import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { PaymentProvider } from '../utils/device';

interface PaymentTypeBoxProps {
  paymentType: PaymentProvider;
}

const PaymentTypeBox = ({ paymentType }: PaymentTypeBoxProps) => {
  const [paymentTypeImg, setPaymentTypeImg] = useState<string>('');

  useEffect(() => {
    switch (paymentType) {
      case PaymentProvider.PayTel:
        setPaymentTypeImg('/images/paytel.png');
        break;
      case PaymentProvider.VivaWallet:
        setPaymentTypeImg('/images/vivaWallet.svg');
        break;
      case PaymentProvider.Adyen:
        setPaymentTypeImg('/images/adyen.svg');
        break;
      case PaymentProvider.WorldLine:
        setPaymentTypeImg('/images/worldline.svg');
        break;
      default:
        setPaymentTypeImg('');
    }
  }, [paymentType]);

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center', justifyContent: 'center', mt: 3 }}>
      <Typography>Powered by</Typography>
      <Box component="img" src={paymentTypeImg} sx={{ maxWidth: '138px', objectFit: 'contain' }}></Box>
    </Stack>
  );
};

export default PaymentTypeBox;
